import React, { useState, useEffect } from 'react'
import { Link, Redirect, useParams } from "react-router-dom"
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { host, Host } from '../../App';
import Axios from 'axios'


function History_OtherExpenses() {
  var [postList, setPostList] = useState([]);
  var [PoNo, setPoNo] = useState({});
  var [poNo, setpoNo] = useState([]);
  var [Item_Desc, setItem_Desc] = useState("");
  var [Date1, setDate1] = useState(new Date());
  var [Postartdate, setPoStartdate] = useState('2021-01-01');
  var [Poenddate, setPoEnddate] = useState(Date1.toISOString().slice(0, 10));
  var [Supplier, setSupplier] = useState({});
  var [ItemNo, setItemNo] = useState("");
  var [Customers, setCustomers] = useState([]);
  var [partNo, setpartNo] = useState([]);
  var [order, setOrder] = useState("1");
  var [Catgory, setCategory] = useState({});
  var [Category_list, setCategory_list] = useState([]);
  let totalAmount = 0;
  let totalUnitCost = 0;

  const [data, setData] = useState([{ PO_No: "", Item_No: "", ReceivedQty: "", ActualReceiveddate: "", Invoice_cost: "", Unit_cost: "", PO_CURRENCY: "", Supplier: "", Item_Description: "", QTY: "", Unit_price_po: "", ETA: "" },]);


  //var token = sessionStorage.getItem("token");
  var token = (window.localStorage.getItem("acessToken"));

  useEffect(() => {
    Axios.get(`https://` + Host + `/api/_HistoryOther_Expenses/search/${JSON.stringify(PoNo)},${JSON.stringify(ItemNo)},${Postartdate},${Poenddate},${JSON.stringify(Supplier)},${order},${token},${JSON.stringify(Item_Desc)},${JSON.stringify(Catgory)}`).then((data) => {
      setData(data.data);
    })
  }, []);
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/Other_Expenses/pono/${token}`).then((data) => {
      setpoNo(data.data)
    });
  }, [])
  // to retreive customers
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/Other_Expenses/suppliers/${token}`).then((data) => {
      setCustomers(data.data)
    });
  }, [])
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/Other_Expenses/itemno/${token}`).then((data) => {
      setpartNo(data.data)
    });
  }, [])
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/Other_Expenses/Category/${token}`).then((data) => {
      setCategory_list(data.data)
    });
  }, [])
  const search = () => {
    Axios.get(`https://` + Host + `/api/_HistoryOther_Expenses/search/${JSON.stringify(PoNo)},${JSON.stringify(ItemNo)},${Postartdate},${Poenddate},${JSON.stringify(Supplier)},${order},${token},${JSON.stringify(Item_Desc)},${JSON.stringify(Catgory)}`).then((data) => {
      setData(data.data);

    })
  }

  const [changedRows, setChangedRows] = useState(new Set());
  const print = () => {
    const changedData = [...changedRows].map(index => {
      return data[index];
    });
    console.log(JSON.stringify(changedData));
  }
  const Delete = (SrNo) => {
    Axios.delete(`https://` + Host + `/api/Other_Expenses/Delete/${SrNo},${token}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message, {
            autoClose: 1000,
            position: toast.POSITION.TOP_RIGHT,
            style: { fontSize: '24px', borderRadius: '10px', color: 'red' },
          });
          search();
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });

  };
  return (
    <div className="MainPage_data">
      <div class="Container">
        <ToastContainer />
        <div class="row">
          <div class="col">
            <div class="row">
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='Category' list="category" onChange={(e) => {
                setCategory((e.target.value).replace(/[{""}]/g, ""))
              }} onSelect={search} />
              <datalist id="category">
                <option></option>
                {Category_list.map(x => {
                  return <option>{x.Category}</option>
                })}
              </datalist>
            </div>

          </div >
          <div class="col">
            <div className='row'>
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='Invoice No' list='Itemno' onChange={(e) => {
                setItemNo(e.target.value)
              }} onSelect={search} />
              <datalist id="Itemno">
                <option></option>
                {partNo.map(x => {
                  return <option>{x.Invoice_Number}</option>
                })}
              </datalist>
            </div>
            <div className='row'>
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='Item DESCRIPTION' list="Itemdesc" onChange={(e) => {
                setItem_Desc(e.target.value.replace(/[{"''"}]/g, ''));
                console.log((e.target.value.replace(/[{"''"}]/g, '')));

              }} onSelect={search} />
              <datalist id="Itemdesc">
                <option></option>
                {data.map(x => {
                  return <option>{x.Description}</option>
                })}
              </datalist>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col" id='moldng'>
                {/* Grab the Itemicular dates */}
                <input type='date' class="form-control form-rounded" id='supplychain-button' placeholder='Invoice DATE' defaultValue={Postartdate} onChange={(e) => {
                  setPoStartdate(e.target.value)
                }} onSelect={search} />
                {/*<button class="btn btn-success" onClick={dates}>Submit</button > */}
                <span class="tooltiptext">Filter By ETA </span>

              </div>
              <div class="col">
                <input type='date' class="form-control form-rounded" id='supplychain-button' placeholder='Operator' defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                  setPoEnddate(e.target.value)
                }} onSelect={search} />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              {/* Grab the Itemicular Supplier */}
              <input type='text' class="form-control form-rounded" placeholder='Supplier' list='Suppliers' onChange={(e) => {
                setSupplier(e.target.value)
              }} onSelect={search} />
              <datalist id="Suppliers">
                <option></option>
                {Customers.map(x => {
                  return <option>{x.Supplier}</option>
                })}
              </datalist>
            </div>

          </div>
        </div >
        <br></br>
        <br></br>
        <div id='RRM_table'>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>INVOICE NO</th>
                <th>INVOICE DATE</th>
                <th>ITEM DESCRIPTION</th>
                <th>SUPPLIER</th>
                <th>CATEGORY</th>
                <th>ETA</th>
                <th>QTY</th>
                <th>UNIT COST</th>
                <th>AMOUNT</th>
                <th>CURRENCY</th>
                <th id="comment">COMMENTS </th>
                <th>Delete</th>
              </tr>
            </thead>
            {data.map((val, index) => {
              totalUnitCost = data.reduce((acc, val) => acc + (val.Unit_cost), 0);
              totalAmount = data.reduce((acc, val) => acc + (val.AMOUNT), 0);
              return (
                <tbody key={index}>
                  <tr key={`${val.PO_No}-${val.Item_No}`}>
                    <td>{val.Invoice_Number}</td>
                    <td>{val.INVOICE_DATE ? val.INVOICE_DATE.slice(0, 10) : ''}</td>
                    <td>{val.Description}</td>
                    <td>{val.Supplier}</td>
                    <td>{val.Category}</td>
                    <td>{val.ETA ? val.ETA.slice(0, 10) : ''}</td>
                    <td>{val.QTY}</td>
                    <td>${val.Unit_cost}</td>
                    <td>${val.Invoice_Amount}</td>
                    <td>{val.CURRENCY}</td>
                    <td id="comment">{val.Comments}</td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() =>
                          window.confirm('Are you sure you want to delete this Entry ' + val.SrNo + ' ?') && Delete(val.SrNo)
                        }
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              )
            }
            )}
          </table>
        </div>
      </div >
    </div >
  )
}

export default History_OtherExpenses;