import React, { useState, useEffect } from 'react';
import { EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import { ItalicButton, BoldButton, UnderlineButton } from '@draft-js-plugins/buttons';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import axios from 'axios';
import './Report.css';
import { host, token } from '../../App';
const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;

const headings = [
  'Health & Safety',
  'Employee Attendance',
  'Machine Update',
  'Assembly Update',
  'Rigid Molding',
  'Flex Molding',
  'Flex Trimming',
  'Shipping',
  'Quality',
  'Equipment/ Maintenance Issues',
  'Task of the day',
  'Comments',
];

const New_ShiftReport = () => {
  const [editorStates, setEditorStates] = useState(() =>
    headings.map(() => EditorState.createEmpty())
  );
  const [files, setFiles] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [shift, setShift] = useState('Morning');
  const supervisor = window.localStorage.getItem('Name')?.replace(/[{""}]/g, '') || '';
  var tokens = (window.localStorage.getItem("acessToken"));
  const [isLoading, setIsLoading] = useState(false);
  const [Status, setStatus] = useState('');

  const queryParams = {
    date: date,
    shift: shift,
    supervisor: supervisor,
    token: tokens,
  };

  useEffect(() => {

    axios.get(`https://` + host + `/api/get/shiftdraft`, { params: queryParams }).then((data) => {
      if (data.data.length > 0) {
        const savedContent = data.data[0].Draft;
        if (savedContent) {
          try {
            const parsedContent = JSON.parse(savedContent);

            // Ensure parsedContent is an array and handle missing headings
            if (Array.isArray(parsedContent)) {
              const updatedEditorStates = headings.map((heading, index) => {
                const rawContent = parsedContent[index];

                // If rawContent is valid, use it; otherwise, create an empty EditorState
                return rawContent
                  ? EditorState.createWithContent(convertFromRaw(rawContent))
                  : EditorState.createEmpty();
              });

              setEditorStates(updatedEditorStates);
            } else {
              console.warn('Saved content is not an array:', parsedContent);

              // Initialize with empty states if saved content is invalid
              setEditorStates(headings.map(() => EditorState.createEmpty()));
            }
          } catch (error) {
            console.error('Failed to parse saved content:', error);

            // Fallback to empty states if parsing fails
            setEditorStates(headings.map(() => EditorState.createEmpty()));
          }
        } else {
          // Fallback to empty states if no saved content
          setEditorStates(headings.map(() => EditorState.createEmpty()));
        }
      }
    }, [date, shift, supervisor])
  });


  const Draft = () => {
    const content = editorStates.map((editorState) =>
      convertToRaw(editorState.getCurrentContent())
    );
    const Content_modified = JSON.stringify(content);
    const formData = new FormData();
    formData.append('content', Content_modified);
    formData.append('date', date);
    formData.append('shift', shift);
    formData.append('supervisor', supervisor);

    axios
      .post(`https://` + host + `/save-draft`, formData)
      .then((response) => {
        // alert('Draft saved successfully!');
      })
      .catch((error) => {
        console.error('There was an error saving the Draft!', error);
        alert('Failed to save the draft.');
      });
  };

  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  const clearAllSections = () => {
    setEditorStates(headings.map(() => EditorState.createEmpty()));
  };

  const clearSection = (index) => {
    const newEditorStates = [...editorStates];
    newEditorStates[index] = EditorState.createEmpty();
    setEditorStates(newEditorStates);
  };
  const handleSubmit = () => {

    setIsLoading(true);
    // for draft
    const draft_content = editorStates.map((editorState) =>
      convertToRaw(editorState.getCurrentContent())
    );
    const Content_modified = JSON.stringify(draft_content);

    //For report
    const content = editorStates.map((editorState, index) => ({
      heading: headings[index],
      content: convertToRaw(editorState.getCurrentContent()),
    }));



    const formData = new FormData();
    formData.append('content', JSON.stringify(content));
    formData.append('draft', Content_modified);
    formData.append('date', date);
    formData.append('shift', shift);
    formData.append('supervisor', supervisor);
    files.forEach((file) => formData.append('files', file));
    console.log('FormData contents:', Array.from(formData.entries()));
    axios
      .post(`https://` + host + `/save-report`, formData)
      .then((response) => {
        setIsLoading(false);
        alert('Report saved successfully!');
        window.location.reload(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('There was an error saving the report!', error);
        alert('Failed to save the report.');
      });
  };

  const handleEditorChange = (editorState, index) => {
    const newEditorStates = [...editorStates];
    newEditorStates[index] = editorState;
    setEditorStates(newEditorStates);
  };

  const handleKeyCommand = (command, index) => {
    const newState = RichUtils.handleKeyCommand(editorStates[index], command);
    if (newState) {
      handleEditorChange(newState, index);
      return 'handled';
    }
    return 'not-handled';
  };


  useEffect(() => {
    axios.get(`https://` + host + `/get_ReportData`, { params: queryParams }).then((response) => {
      if (response.data && response.data[0]) {
        if (response.data[0].Status === 'Submitted') {
          setStatus('Submitted');
        } else {
          setStatus('draft');
        }
      } else {
        // Handle cases where response.data or response.data[0] does not exist
        setStatus('draft');
      }
    });
  }, [date, shift, supervisor]);

  return (
    <div id="new-shift-report-block" style={{ padding: '20px', margin: 'auto' }}>
      <div id="new_shift_report_block">
        <div style={{ marginBottom: '20px' }}>
          <label style={{ marginLeft: '20px' }}>
            Date:
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
              className="form-control form-rounded"
              style={{ marginLeft: '10px', padding: '5px' }}
            />
          </label>
          <label style={{ marginLeft: '20px' }}>
            Shift:
            <select
              value={shift}
              className="form-control form-rounded"
              onChange={(e) => setShift(e.target.value)}
              style={{ marginLeft: '10px', padding: '5px' }}
            >
              <option value="Morning">Morning</option>
              <option value="Evening">Evening</option>
            </select>
          </label>
          <button className='form-rounded btn btn-primary' onClick={clearAllSections}>Clear Report</button>
        </div>
        <div className="Shift_block" style={{ border: '1px solid gray', padding: '1%' }}>
          {headings.map((heading, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <p style={{ fontSize: '24px' }} id="new_shift-heading">{heading}<button class="btn btn-secondary" id="mobileView" onClick={() => clearSection(index)} >Clear {heading}
              </button></p>


              <div
                style={{
                  minHeight: '100px',
                  padding: '10px',
                  background: 'ghostwhite',
                  borderRadius: '4px',
                }}
                onClick={() => document.querySelectorAll('.DraftEditor-root')[index].focus()}
              >
                <Editor
                  editorState={editorStates[index]}
                  onChange={(editorState) => handleEditorChange(editorState, index)}
                  handleKeyCommand={(command) => handleKeyCommand(command, index)}
                  plugins={[inlineToolbarPlugin]}
                  className="form-control form-rounded"
                  onBlur={Draft}
                  id={`shift_section_editor_${index}`}
                />
                {/* Inline toolbar to provide text formatting options */}
                <InlineToolbar>
                  {(externalProps) => (
                    <>
                      <BoldButton {...externalProps} />
                      <ItalicButton {...externalProps} />
                      <UnderlineButton {...externalProps} />
                    </>
                  )}
                </InlineToolbar>
              </div>
            </div>
          ))}
        </div>
        <input
          type="file"
          className="form-control form-rounded"
          multiple
          onChange={handleFileChange}
          style={{ marginBottom: '20px' }}
        />
        <button
          onClick={handleSubmit}
          style={{
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: Status === 'Submitted' ? 'not-allowed' : 'pointer',
          }}
          disabled={Status === 'Submitted'}
        >
          Submit Report
        </button>

      </div>
      {
        isLoading ?
          <div className='overlay'>
            <div class="text-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div> : null
      }

    </div >
  );
};

export default New_ShiftReport;
