import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Link, Redirect, useParams } from "react-router-dom"
import './MainPage.css'
import { host } from '../../App';
import { CheckBox } from '@mui/icons-material';

function Main() {
  const [postList, setPostList] = useState([]);
  const [Operator, setOperator] = useState({});
  const [Date1, setDate1] = useState(new Date());
  const [startdate, setStartdate] = useState(Date1.toISOString().slice(0, 10));
  const [enddate, setEnddate] = useState(Date1.toISOString().slice(0, 10));
  const [customer, setCustomer] = useState({});
  const [Department, setDepartment] = useState({});
  const [Machine, setMachine] = useState({});
  const [partInternalNo, setPartInternalNo] = useState({});
  const [operators, setoperators] = useState([]);
  const [Customers, setCustomers] = useState([]);
  const [part_dataset, setpart_dataset] = useState([]);
  var token = (window.localStorage.getItem("acessToken"));
  const [selectedRows, setSelectedRows] = useState({});
  var [del, setdel] = useState(1);
  var [shift, setShift] = useState("");
  const [Machine_list, setMachine_list] = useState([]);
  const queryParams = {
    startdate: startdate,
    enddate: enddate,
    token: token,
    customer: JSON.stringify(customer),
    Operator: JSON.stringify(Operator),
    partInternalNo: JSON.stringify(partInternalNo),
    shift: JSON.stringify(shift),
    Department: JSON.stringify(Department),
    Machine: JSON.stringify(Machine)
  };

  useEffect(() => {
    var timer = setInterval(() => setDate1(new Date()), 10)
    return function cleanup() {
      clearInterval(timer)
    }
  });
  // Default Data 
  useEffect(() => {
    console.log("called Production schedule", startdate, enddate);
    Axios.get(`https://${host}/realapi/Production/schedule`, { params: queryParams })
      .then((response) => {
        console.log("Fetched Data:", response.data);
        setPostList(response.data); // Directly set the fetched data
      })
      .catch((error) => {
        console.error("Error fetching production schedule:", error);
      });
  }, [shift, startdate, customer, Operator, enddate, partInternalNo, Department, Machine]);
  // to retreive operators
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/Operators/${token}`).then((data) => {
      setoperators(data.data)
    });
  }, [])

  //store values to delete data
  const handleCheckboxChange = (key) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [key]: !prevSelectedRows[key],
    }));
    console.log(selectedRows);
  };

  // to retreive customers
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/Customers/${token}`).then((data) => {
      setCustomers(data.data)
    });
  }, [])

  // to retreive part dataset
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/part_dataset/${token}`).then((data) => {
      setpart_dataset(data.data)
    });
  }, [])
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/Machines/${token}`).then((data) => {
      setMachine_list(data.data)
    });
  }, [])
  // method to retrieve data combinely
  const search = () => {
    console.log("search called!");
    Axios.get(`https://` + host + `/realapi/search/${JSON.stringify(Operator)},${startdate},${enddate},${JSON.stringify(customer)},${JSON.stringify(partInternalNo)},${token}`).then((data) => {

      setPostList(data.data)
    })
  }
  // method to retrieve data combinely
  const Delete = (date, operator, partnumber) => {
    Axios.delete(`https://` + host + `/realapi/delete/${date},${operator},${partnumber},${token}`).catch(function (error) {
      console.log(error.response);
      setdel(del + 1);
    });
    setTimeout(() => {
      search();
    }, 2000);
  }
  // method to retrieve data combinely
  const uniqueSections = [...new Set(Machine_list.map((x) => x.Section))];
  return (
    <div className="MainPage_data">
      <div class="Container">
        <div class="row" id="filter-row">
          <div class="col-md-3 col-sm-6 mb-3">
            <div class="row mb-2">
              <input type="text" class="form-control form-rounded" placeholder="Operator" list="operators" onChange={(e) => setOperator(e.target.value)} onSelect={search} />
              <datalist id="operators">
                <option></option>
                {operators.map(x => <option key={x.Name}>{x.Name}</option>)}
              </datalist>
            </div>

          </div>

          <div class="col-md-3 col-sm-6 mb-3">
            <div class="row mb-2">
              <input type="date" class="form-control form-rounded" defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => setStartdate(e.target.value)} onSelect={search} />
            </div>
            <div class="row">
              <input type="date" class="form-control form-rounded" defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => setEnddate(e.target.value)} onSelect={search} />
            </div>
          </div>

          <div class="col-md-3 col-sm-6 mb-3">
            <div class="row mb-2">
              <input type="text" class="form-control form-rounded" placeholder="Customer" list="Customers" onChange={(e) => setCustomer(e.target.value)} onSelect={search} />
              <datalist id="Customers">
                <option></option>
                {Customers.map(x => <option key={x.Customer}>{x.Customer}</option>)}
              </datalist>
            </div>
            <div class="row">
              <select name="shift" className="form-control form-rounded" placeholder="Select Shift" value={shift} onChange={(e) => setShift(e.target.value)}>
                <option value="">--Select Shift--</option>
                <option value="Morning_Operators">Morning Shift</option>
                <option value="Night_Operators">Night Shift</option>
                <option value="Woodshop">Woodshop/Upholstery</option>
              </select>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 mb-3">
            <div class="row mb-2">
              <input type="text" class="form-control form-rounded" placeholder="Internal Part Number" list="InternalPartNo" onChange={(e) => setPartInternalNo(e.target.value)} onSelect={search} />
              <datalist id="InternalPartNo">
                <option></option>
                {part_dataset.map(x => <option key={x.Sr_No}>{x.Part_internalNo}</option>)}
              </datalist>
            </div>
            <div class="row">
              <div class="col"><input type="text" class="form-control form-rounded" placeholder="Select Department" list="Department" onChange={(e) => setDepartment(e.target.value)} onSelect={search} />
                <datalist id="Department">
                  <option></option>
                  {uniqueSections.map((section, index) => (
                    <option key={index} value={section}>{section}</option>
                  ))}
                </datalist></div>
              <div class="col"><input type="text" class="form-control form-rounded" placeholder="Select Machine" list="Machine" onChange={(e) => setMachine(e.target.value)} onSelect={search} />
                <datalist id="Machine">
                  <option></option>
                  {Machine_list.map(x => <option key={x.Machines}>{x.Machines}</option>)}
                </datalist></div>

            </div>
          </div>


        </div>
        <div>
          <Link to="/Scheduling">
            <button type='button' class="btn btn-success">
              <span class="glyphicon glyphicon-star" aria-hidden="true"></span> Back</button>
          </Link>
        </div>

        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Select</th>
              <th>Date</th>
              <th>Operator</th>
              <th>Part Internal No</th>
              <th>PART No</th>
              <th>CUSTOMER</th>
              <th>Part Description</th>
              <th>Target</th>
              <th>Work Station</th>
              <th>Produced</th>
              <th>Scrap</th>
              <th>Operator-2 </th>
              <th>Supervisor Notes</th>
              <th>Production</th>
              <th>Delete</th>
            </tr>
          </thead>
          {postList.map((val, key) => {
            return (
              <tbody id="tbody">
                <tr key={key}>
                  <td><input
                    type="checkbox"
                    checked={selectedRows[((val.Date.slice(0, 10)) + (val.Operator) + (val.PartNo))]}
                    onChange={() => handleCheckboxChange(((val.Date.slice(0, 10)), (val.Operator), (val.PartNo)))}
                  /></td>
                  <td>{val.Date.slice(0, 10)}</td>
                  <td>{val.Operator}</td>
                  <td>{val.Part_internalNo}</td>
                  <td>{val.PartNo}</td>
                  <td>{val.Customer}</td>
                  <td>{val.Part_description}</td>
                  <td>{val.Target}</td>
                  <td>{val.work_station}</td>
                  <td>{val.Produced}</td>
                  <td> {val.Scrap}</td>
                  <td>{val.Operator_2}</td>
                  <td>{val.Supervisor_notes}</td>
                  <td>{((val.Produced / val.Target) * 100).toFixed(2)}%</td>
                  <td><button class="btn btn-danger" onClick={() => { window.confirm('Are you sure you want to delete this Entry?',) && Delete((val.Date.slice(0, 10)), (val.Operator), (val.PartNo)) }}> Delete</button></td>
                </tr>
              </tbody>
            )
          })}
        </table>
      </div>
    </div >
  )
}

export default Main;