import React, { useState, useEffect } from 'react'
import { Link, Redirect, useParams } from "react-router-dom"
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { host, Host } from '../../App';
import Axios from 'axios'


function Received_PO_Supplies() {
  var [del, setdel] = useState(1);
  var int = 4;
  var [postList, setPostList] = useState([]);
  var [PoNo, setPoNo] = useState({});
  var [poNo, setpoNo] = useState([]);
  var [Item_Desc, setItem_Desc] = useState("");
  var [Date1, setDate1] = useState(new Date());
  var [Postartdate, setPoStartdate] = useState('2020-01-01');
  var [Poenddate, setPoEnddate] = useState(Date1.toISOString().slice(0, 10));
  var [Supplier, setSupplier] = useState({});
  var [ItemNo, setItemNo] = useState("");
  var [Category, setCategory] = useState({});
  var [Category_list, setCategory_list] = useState([]);
  var [Customers, setCustomers] = useState([]);
  var [partNo, setpartNo] = useState([]);
  var [order, setOrder] = useState("1");
  const [data, setData] = useState([]);
  const [Edit, setEdit] = useState(false);


  const updateHandlers = (index, newData) => {
    setData(prevData => {
      const updatedData = [...prevData];
      updatedData[index] = newData;
      return updatedData;
    });
  };

  //var token = sessionStorage.getItem("token");
  var token = (window.localStorage.getItem("acessToken"));

  useEffect(() => {
    Axios.get(`https://` + host + `/api/Received_Supplies/search/${JSON.stringify(PoNo)},${JSON.stringify(ItemNo)},${Postartdate},${Poenddate},${JSON.stringify(Supplier)},${order},${token},${JSON.stringify(Item_Desc)},${JSON.stringify(Category)}`).then((data) => {
      const transformedData = data.data.map((item) => ({
        SrNo: item.SrNo || "",
        PO_No: item.PO_No || "",
        PO_Date: item.PO_Date || "",
        Item_No: item.Item_No || "",
        ReceivedQty: item.ReceivedQty || "",
        ActualReceiveddate: item.ActualReceiveddate || "",
        Invoice_cost: item.Invoice_cost || "",
        Item_Description: item.Item_Description || "",
        Supplier: item.Supplier || "",
        Comments: item.Comments || "",
        Unit_cost: item.Unit_cost || "",
        Unit_price_db: item.COST || "",
        PO_CURRENCY: item.CURRENCY || "",
        QTY: item.Remained_parts || "0",
        PO_QTY: item.QTY || "0",
        ETA: item.ETA || "",
        GRN_No: item.GRN_No || ""
      }));

      setData(transformedData);
    })
  }, []);
  useEffect(() => {
    Axios.get(`https://` + host + `/api/Received_Supplies/pono/${token}`).then((data) => {
      setpoNo(data.data)
    });
  }, [])
  // to retreive customers
  useEffect(() => {
    Axios.get(`https://` + host + `/api/Received_Supplies/suppliers/${token}`).then((data) => {
      setCustomers(data.data)
    });
  }, [])
  useEffect(() => {
    Axios.get(`https://` + host + `/api/Received_Supplies/itemno/${token}`).then((data) => {
      setpartNo(data.data)
    });
  }, [])
  useEffect(() => {
    Axios.get(`https://` + host + `/api/Received_Supplies/Category/${token}`).then((data) => {
      setCategory_list(data.data)
    });
  }, [])
  const search = () => {
    Axios.get(`https://` + host + `/api/Received_Supplies/search/${JSON.stringify(PoNo)},${JSON.stringify(ItemNo)},${Postartdate},${Poenddate},${JSON.stringify(Supplier)},${order},${token},${JSON.stringify(Item_Desc)},${JSON.stringify(Category)}`).then((data) => {
      const transformedData = data.data.map((item) => ({
        SrNo: item.SrNo || "",
        PO_No: item.PO_No || "",
        PO_Date: item.PO_Date || "",
        Item_No: item.Item_No || "",
        ReceivedQty: item.ReceivedQty || "",
        ActualReceiveddate: item.ActualReceiveddate || "",
        Invoice_cost: item.Invoice_cost || "",
        Item_Description: item.Item_Description || "",
        Supplier: item.Supplier || "",
        Comments: item.Comments || "",
        Unit_cost: item.Unit_cost || "",
        Unit_price_db: item.COST || "",
        PO_CURRENCY: item.CURRENCY || "",
        QTY: item.Remained_parts || "0",
        PO_QTY: item.QTY || "0",
        ETA: item.ETA || "",
        GRN_No: item.GRN_No || ""
      }));

      setData(transformedData);
    })
  }
  const ToggleEdit = () => {
    setEdit(!Edit);
  }

  const [changedRows, setChangedRows] = useState(new Set());
  const handleUpdate = (e, index, key) => {
    const { value } = e.target;
    updateData(index, key, value);
  };
  {/* const updateData = (index, key, value) => {
    setData(prevData => {
      const newData = [...prevData];
      newData[index][key] = value;
      return newData;
    });
    // Add the index of the changed row to the set of changed rows
    //setChangedRows(prevRows => new Set(prevRows).add(index));
    const isValid = (row) => row.ReceivedQty && row.ActualReceiveddate;

    setData(prevData => {
      const newData = [...prevData];
      newData[index][key] = value;

      if (isValid(newData[index])) {
        setChangedRows(prevRows => {
          const newSet = new Set(prevRows);
          newSet.add(index);
          return newSet;
        });
      }

      return newData;
    });
  };*/}
  const updateData = (index, key, value) => {
    setData(prevData => {
      const newData = [...prevData];
      newData[index][key] = value;

      // Check if both ReceivedQty and ActualReceiveddate are set and valid
      const isValid = (row) => {
        // Helper function to check if a date is valid
        const isValidDate = (date) => {
          return date && date !== '0000-00-00' && !isNaN(new Date(date).getTime());
        };

        return (
          row.ReceivedQty &&
          row.Invoice_cost &&
          isValidDate(row.ActualReceiveddate)
        );
      };
      if (isValid(newData[index])) {
        setChangedRows(prevRows => {
          const newSet = new Set(prevRows);
          newSet.add(index);
          return newSet;
        });
      }

      return newData;
    });
  };
  const Update = () => {

    setdel(del = del + 1)
    const changedData = [...changedRows].map(index => {
      return data[index];
    });
    if (changedData.length > 0) {
      Axios.post(`https://` + host + `/PO_Supplies/Receiveddata/Update`, { changedData: JSON.stringify(changedData), token: token })
        .then((response) => {
          if (response.status === 200) {
            const numberInputs = document.querySelectorAll('input[type="number"]');
            numberInputs.forEach(input => {
              input.value = '';
            });
            toast.success(response.data.message, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
              style: { fontSize: '24px', borderRadius: '10px' },
            });
            search();
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
    } else {
      toast.error("No Valid Data !", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
        style: { fontSize: '24px', borderRadius: '10px' },
      });
    }
  };
  //logics for Update PO  

  const [changedRows_PO, setChangedRows_PO] = useState(new Set());
  const handleUpdate_PO = (e, index, key) => {
    const { value } = e.target;
    updateData_PO(index, key, value);

  };
  const updateData_PO = (index, key, value) => {
    setData(prevData => {
      const newData = [...prevData];
      newData[index][key] = value;
      return newData;
    });
    // Add the index of the changed row to the set of changed rows
    setChangedRows_PO(prevRows => new Set(prevRows).add(index));
  };
  const Update_PO = (PO_No, Item_No) => {
    setdel(del = del + 1)
    const changedData = [...changedRows_PO].map(index => {
      return data[index];
    });
    Axios.post(`https://` + host + `/Supplies/PO/Update`, { changedData: JSON.stringify(changedData), token: token })
      .then((response) => {
        if (response.status === 200) {
          const numberInputs = document.querySelectorAll('input[type="number"]');
          numberInputs.forEach(input => {
            input.value = '';
          });
          toast.success(response.data.message, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
            style: { fontSize: '24px', borderRadius: '10px' },
          });
          setEdit(false);
          search();
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });

  };
  const Delete = (SrNo) => {
    setdel(del = del + 1)
    Axios.delete(`https://` + host + `/api/ReceivedSupplies/Delete/${SrNo},${token}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message, {
            autoClose: 1000,
            position: toast.POSITION.TOP_RIGHT,
            style: { fontSize: '24px', borderRadius: '10px', color: 'red' },
          });
          search();
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });

  };
  return (
    <div className="MainPage_data">
      <div class="Container">
        <ToastContainer />
        <div class="row">
          <div class="col">
            <div class="row">
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='PO NO' list="PoNo" onChange={(e) => {
                setPoNo(e.target.value)
              }} onSelect={search} />
              <datalist id="PoNo">
                <option></option>
                {poNo.map(x => {
                  return <option>{x.PO_No}</option>
                })}
              </datalist>
            </div>
            <div class="row">
              <div class="col" id='supplychain-button'>
                <Link to="/Historyreceivedsupplies">
                  <input type="button" class="btn btn-success" defaultValue="History Supplies" />
                </Link>
              </div >
              <div class="col" id='mobileView'>
                <input type="button" class="btn btn-success" defaultValue="Update Supplies" onClick={() => { ToggleEdit() }} />
              </div >
            </div>
          </div>
          <div class="col">
            <div className='row'>
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='Item No' list='Itemno' onChange={(e) => {
                setItemNo(e.target.value)
              }} onSelect={search} />
              <datalist id="Itemno">
                <option></option>
                {partNo.map(x => {
                  return <option>{x.Item_No}</option>
                })}
              </datalist>
            </div>
            <div className='row'>
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='Item DESCRIPTION' list="Itemdesc" onChange={(e) => {
                setItem_Desc(e.target.value);
              }} onSelect={search} />
              <datalist id="Itemdesc">
                <option></option>
                {postList.map(x => {
                  return <option></option>
                })}
              </datalist>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col" id='moldng'>
                {/* Grab the Itemicular dates */}
                <input type='date' class="form-control form-rounded" id='supplychain-button' placeholder='PO SHIP DATE' defaultValue={Postartdate} onChange={(e) => {
                  setPoStartdate(e.target.value)
                }} onSelect={search} />
                {/*<button class="btn btn-success" onClick={dates}>Submit</button > */}
                <span class="tooltiptext">Filter By ETA </span>

              </div>
              <div class="col">
                <input type='date' class="form-control form-rounded" id='supplychain-button' placeholder='Operator' defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                  setPoEnddate(e.target.value)
                }} onSelect={search} />
              </div>
            </div>
            <div class="row">
              {/* Grab the Itemicular Operator name */}
              <input type='text' class="form-control form-rounded" placeholder='Category' list="category" onChange={(e) => {
                setCategory((e.target.value).replace(/[{""}]/g, ""))
              }} onSelect={search} />
              <datalist id="category">
                <option></option>
                {Category_list.map(x => {
                  return <option>{x.Category}</option>
                })}
              </datalist>
            </div>
          </div>
          <div class="col">
            <div class="row">
              {/* Grab the Itemicular Supplier */}
              <input type='text' class="form-control form-rounded" placeholder='Supplier' list='Suppliers' onChange={(e) => {
                setSupplier(e.target.value)
              }} onSelect={search} />
              <datalist id="Suppliers">
                <option></option>
                {Customers.map(x => {
                  return <option>{x.Supplier}</option>
                })}
              </datalist>
            </div>
            <div class="row">
              <select
                type="select"
                class="form-control form-rounded"
                id="order"
                name="order"
                onChange={(e) => {
                  setOrder(e.target.value);
                  order = e.target.value;
                  search();
                }}
              > <option value="1">Open Orders</option>
                <option value="0"> Open/Closed Orders</option>
              </select>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <div id='RRM_table'>
          {Edit ? <table class="table table-bordered">
            <thead>
              <tr>
                <th>PO NO</th>
                <th>ITEM NO</th>
                <th>PO DATE</th>
                <th>ITEM DESCRIPTION</th>
                <th>SUPPLIER</th>
                <th>PO QTY</th>
                <th>ETA</th>
                <th>CURRECNCY</th>
                <th>Unit Cost(PO)</th>
                <th id="comment">COMMENTS </th>
                <th>Update</th>
                <th>Delete</th>
              </tr>
            </thead>
            {data.map((val, index) => (
              <tbody key={index}>
                <tr key={`${val.PO_No}-${val.Item_No}`}>
                  <td>{val.PO_No}
                  </td>
                  <td>{val.Item_No}</td>
                  <td><input
                    type="date"
                    className="form-control form-rounded"
                    id="PO_Date"
                    Value={val.PO_Date ? (data[index].PO_Date.slice(0, 10)) : null} onBlur={(e) => handleUpdate_PO(e, index, 'PO_Date')}
                  /></td>
                  <td><input
                    type="text"
                    className="form-control form-rounded"
                    id="PO_Date"
                    Value={data[index].Item_Description}
                    onBlur={(e) => handleUpdate_PO(e, index, 'Item_Description')}
                  /></td>
                  <td><input
                    type="text"
                    className="form-control form-rounded"
                    id="PO_Date"
                    Value={data[index].Supplier}
                    onBlur={(e) => handleUpdate_PO(e, index, 'Supplier')}
                  /></td>
                  <td><input
                    type="number"
                    className="form-control form-rounded"
                    id="PO_Date"
                    Value={data[index].PO_QTY}
                    onBlur={(e) => handleUpdate_PO(e, index, 'PO_QTY')}
                  /></td>
                  <td><input
                    type="date"
                    className="form-control form-rounded"
                    id="PO_Date"
                    Value={data[index].ETA.slice(0, 10)}
                    onBlur={(e) => handleUpdate_PO(e, index, 'ETA')}
                  /></td>
                  <td><input
                    type="text"
                    className="form-control form-rounded"
                    id="PO_Date"
                    Value={data[index].PO_CURRENCY}
                    onBlur={(e) => handleUpdate_PO(e, index, 'PO_CURRENCY')}
                    list='currency'
                  />
                    <datalist id='currency'>
                      <option>CAD</option>
                      <option>USD</option>
                    </datalist></td>
                  <td><input
                    type="text"
                    className="form-control form-rounded"
                    id="PO_Date"
                    Value={data[index].Unit_cost}
                    onBlur={(e) => handleUpdate_PO(e, index, 'Unit_cost')}
                  /></td>
                  <td id="comment"><input
                    type="text"
                    className="form-control form-rounded"
                    Value={data[index].Comments}
                    onBlur={(e) => handleUpdate_PO(e, index, 'Comments')}
                  /></td>
                  <td>
                    <button
                      className="btn btn-success"
                      onClick={() =>
                        Update_PO()
                      }
                    >
                      Update PO
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() =>
                        window.confirm('Are you sure you want to delete this Entry ?') && Delete(val.SrNo)
                      }
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            )
            )}
          </table> : <table class="table table-bordered">
            <thead>
              <tr>
                <th>PO NO</th>
                <th>ITEM NO</th>
                <th>PO DATE</th>
                <th>ITEM DESCRIPTION</th>
                <th>SUPPLIER</th>
                <th>Remained QTY</th>
                <th>ETA</th>
                <th>RECEIVING QTY</th>
                <th>RECEIVING DATE</th>
                <th>CURRENCY</th>
                <th>Unit Cost(PO)</th>
                <th>Amount(PO)</th>
                <th>Invoice Cost</th>
                <th>GRN No</th>
                <th id="comment">COMMENTS </th>
                <th>Update</th>
                <th>Delete</th>
              </tr>
            </thead>
            {data.map((val, index) => (
              <tbody key={index}>
                <tr key={`${val.PO_No}-${val.Item_No}`}>
                  <td>{val.PO_No}</td>
                  <td>{val.Item_No}</td>
                  <td>{val.PO_Date ? val.PO_Date.slice(0, 10) : ''}</td>
                  <td>{val.Item_Description}</td>
                  <td>{val.Supplier}</td>
                  <td>{val.QTY}</td>
                  <td>{val.ETA ? val.ETA.slice(0, 10) : ''}</td>
                  <td>
                    <input
                      type="number"
                      className="form-control form-rounded"
                      id="Receivedqty"
                      Value={data[index].ReceivedQty}
                      onBlur={(e) => {
                        if (e.target.value > val.QTY) {
                          window.alert("Received QTY cannot exceed PO QTY");
                          // Clear the input field
                          const newData = [...data];
                          newData[index].ReceivedQty = ''; // Set the value to empty
                          setData(newData); // Update the state
                        } else {
                          handleUpdate(e, index, 'ReceivedQty');
                        }
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="date"
                      className="form-control form-rounded"
                      Value={data[index].ActualReceiveddate}
                      onBlur={(e) => handleUpdate(e, index, 'ActualReceiveddate')}
                    />
                  </td>
                  <td>{val.PO_CURRENCY}</td>
                  <td>${val.Unit_cost}</td>
                  <td>${((val.Unit_cost) * (val.QTY)).toFixed(2)}</td>
                  <td>
                    <input
                      type="number"
                      className="form-control form-rounded"
                      Value={data[index].Invoice_cost}
                      onBlur={(e) => handleUpdate(e, index, 'Invoice_cost')}
                    />
                  </td>
                  <td><input
                    type="text"
                    className="form-control form-rounded"
                    Value={data[index].GRN_No}
                    onBlur={(e) => handleUpdate(e, index, 'GRN_No')}
                  /></td>
                  <td id="comment"><input
                    type="text"
                    className="form-control form-rounded"
                    Value={data[index].Comments}
                    onBlur={(e) => handleUpdate(e, index, 'Comments')}
                  /></td>
                  <td>
                    <button
                      className="btn btn-success"
                      onClick={() =>
                        Update(
                          val.PO_No,
                          val.Item_No,
                          val.PO_SHIP_DATE,
                          val.Item_DESCRIPTION,
                          val.Supplier,
                          val.COMMENTS,
                          val.PO_DATE,
                          val.ReceivedQty
                        )
                      }
                    >
                      Update
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() =>
                        window.confirm('Are you sure you want to delete this Entry?') &&
                        Delete(val.SrNo)
                      }
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            )
            )}

          </table>}
        </div>
      </div>
    </div >
  )
}

export default Received_PO_Supplies