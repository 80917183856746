import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Link, Redirect, useParams } from "react-router-dom"
import '../Pages/MainPage.css'
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { host } from '../../App';
import { CheckBox } from '@mui/icons-material';
import { format } from 'date-fns';
import real_l from "../real_logo.png";
import "../../ShippingData.css";
import "../../Add_part_Dataset/Add_Part_Dataset.css";

function Scheduling() {
  const [postList, setPostList] = useState([]);
  const [Operator, setOperator] = useState({});
  const [Date1, setDate1] = useState(new Date());
  const formattedDate = new Date(Date1).toLocaleDateString();
  const formattedTime = new Date(Date1).toLocaleTimeString();
  const [startdate, setStartdate] = useState(Date1.toISOString().slice(0, 10));
  const [enddate, setEnddate] = useState(Date1.toISOString().slice(0, 10));
  const [customer, setCustomer] = useState({});
  const [partInternalNo, setPartInternalNo] = useState({});
  const [Department, setDepartment] = useState({});
  const [Machine, setMachine] = useState({});
  const [operators, setoperators] = useState([]);
  const [Customers, setCustomers] = useState([]);
  const [part_dataset, setpart_dataset] = useState([]);
  const token = (window.localStorage.getItem("acessToken"));
  var user = (window.localStorage.getItem("Name"));
  const [Machine_list, setMachine_list] = useState([]);
  const [Edit, setEdit] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [ChangedRows_update, setChangedRows_update] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedDate, setSelectedDate] = useState(Date1.toISOString().slice(0, 10));
  const [isLoading, setIsLoading] = useState(false);
  const [AllColumns, setAllColumns] = useState(false);
  var [shift, setShift] = useState("");

  const [data, setData] = useState([{ Date: Date1, Operator: '', Part_internalNo: '', PartNo: '', Customer: '', Part_description: '', Target: '', work_station: '', Supervisor_notes: '', Operator_2: '', Schedule_entry: user, Schedule_Date_Time: Date1, Shift: shift },]);

  const handleCheckboxChange = (e, row) => {
    const isChecked = e.target.checked;
    const { SR_No } = row;
    if (isChecked) {
      // Add to selectedRows
      setSelectedRows([...selectedRows, { SR_No }]);
    } else {
      // Remove from selectedRows
      setSelectedRows(selectedRows.filter(item => item.SR_No !== SR_No));
    }
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      // Select all rows
      setSelectedRows(postList.map(row => ({ SR_No: row.SR_No })));
    } else {
      // Deselect all rows
      setSelectedRows([]);
    }
  };

  const ToggleEdit = () => {
    setEdit(!Edit);
  }

  useEffect(() => {
    var timer = setInterval(() => setDate1(new Date()), 10)
    return function cleanup() {
      clearInterval(timer)
    }
  });
  const getFormattedDateTime = (date) => {
    return format(date, 'yyyy-MM-dd HH:mm:ss');
  };
  const queryParams = {
    startdate: startdate,
    enddate: enddate,
    token: token,
    customer: JSON.stringify(customer),
    Operator: JSON.stringify(Operator),
    partInternalNo: JSON.stringify(partInternalNo),
    shift: JSON.stringify(shift),
    Department: JSON.stringify(Department),
    Machine: JSON.stringify(Machine)
  };

  const addNewRow = (index, operatorName, work_station) => {
    const newData = [...data];
    const newRow = { Date: '', Operator: operatorName, Part_internalNo: '', PartNo: '', Customer: '', Part_description: '', Target: '', work_station: work_station, Supervisor_notes: '', Operator_2: '', Schedule_entry: user, Schedule_Date_Time: Date1, Shift: shift };
    newData.splice(index + 1, 0, newRow);
    setData(newData);
  };

  // Default Data 
  useEffect(() => {
    console.log("called Production schedule", startdate, enddate);
    Axios.get(`https://${host}/realapi/Production/schedule`, { params: queryParams })
      .then((response) => {
        console.log("Fetched Data:", response.data);
        setPostList(response.data); // Directly set the fetched data
      })
      .catch((error) => {
        console.error("Error fetching production schedule:", error);
      });
  }, [selectedDate, shift, AllColumns, Edit, startdate, enddate]);


  // to retreive operators
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/Operators/${token}`).then((data) => {
      setoperators(data.data)
    });
  }, [])


  // to retreive customers
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/Customers/${token}`).then((data) => {
      setCustomers(data.data)
    });
  }, [])

  // to retreive part dataset
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/part_dataset/${token}`).then((data) => {
      setpart_dataset(data.data)
    });
  }, [])

  //retrieve machine list
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/Machines/${token}`).then((data) => {
      setMachine_list(data.data)
    });
  }, [])
  const uniqueSections = [...new Set(Machine_list.map((x) => x.Section))];

  // Calculate Yesterday and Tomorrow
  const yesterday = new Date(Date1);
  yesterday.setDate(Date1.getDate() - 1);
  const tomorrow = new Date(Date1);
  tomorrow.setDate(Date1.getDate() + 1);

  const handleDateChange = (e) => {
    const dateValue = e.target.value;
    let date;
    switch (dateValue) {
      case 'yesterday':
        date = yesterday.toISOString().slice(0, 10);
        break;
      case 'today':
        date = Date1.toISOString().slice(0, 10);
        break;
      case 'tomorrow':
        date = tomorrow.toISOString().slice(0, 10);
        break;
      default:
        date = Date1.toISOString().slice(0, 10);
    }
    setSelectedDate(date);
    setStartdate(date);
    setEnddate(date);

  };

  const submitValue = (e, index) => {
    const { value } = e.target;
    const partDetails = part_dataset.find((x) => x.Part_internalNo === value);
    if (partDetails) {
      const newData = [...postList];
      newData[index].Part_internalNo = value;
      newData[index].Customer = partDetails.Customer;
      newData[index].PartNo = partDetails.PartNo;
      newData[index].Part_description = partDetails.Part_description;
      setPostList(newData);
    }
  };

  const submitValue_Singlepart = (e, index) => {
    const { value } = e.target;
    const partDetails = part_dataset.find((x) => x.Part_internalNo === value);
    if (partDetails) {
      const newData = [...data];
      newData[index].Part_internalNo = value;
      newData[index].Customer = partDetails.Customer;
      newData[index].PartNo = partDetails.PartNo;
      newData[index].Part_description = partDetails.Part_description;
      setData(newData);
    }
  };
  // method to retrieve data combinely



  const search = () => {
    Axios.get(`https://` + host + `/realapi/Production/schedule`, { params: queryParams }).then((data) => {
      setPostList(data.data)
    })
  }
  // method to retrieve data combinely
  const Delete = () => {
    setIsLoading(true);
    Axios.delete(`https://` + host + `/realapi/schedule/delete`, { data: { selectedRows, token } }).then((response) => {
      if (response.status === 200) {
        window.alert(response.data.message);
        setIsLoading(false);
        setTimeout(() => { search() }, 2000);
      }
    })
    setIsLoading(false);

  }

  const [changedRows, setChangedRows] = useState(new Set());

  const handleUpdate = (e, index, key) => {
    const { value } = e.target;
    const newData = [...postList];
    newData[index][key] = value;

    console.log(`Updating field: ${key} at index: ${index} with value: ${e.target.value}`);

    // Check if the key is Part_internalNo, then call submitValue
    if (key === 'Part_internalNo') {
      submitValue(e, index);
    } else {
      setPostList(newData);
    }
    updateData(index, key, value);
    // setTimeout(() => { Update() }, 3000);
  };

  // method to retrieve data combinely
  const updateData = (index, key, value) => {
    setPostList(prevData => {
      const newData = [...prevData];
      newData[index][key] = value;

      const isValid = (row) => {
        // Helper function to check if a date is valid
        const isValidDate = (date) => {
          return date && date !== '0000-00-00' && !isNaN(new Date(date).getTime());
        };

        return (
          row.Produced &&
          isValidDate(row.Date)
        );
      };

      if (isValid(newData[index])) {
        setChangedRows(prevRows => {
          const newSet = new Set(prevRows);
          newSet.add(index);
          return newSet;
        });
      }

      setChangedRows_update(prevRows => {
        const newSet = new Set(prevRows);
        newSet.add(index);
        return newSet;
      });
      return newData;
    });
  };


  const Update = () => {

    let changedData = [...changedRows].map(index => {
      return postList[index];
    });

    if (changedData.length > 0) {

      setIsLoading(true);
      Axios.post(`https://` + host + `/schedule/Update`, { changedData: JSON.stringify(changedData), token: token, user: user, date: (getFormattedDateTime(Date1)) })
        .then((response) => {
          if (response.status === 200) {
            toast.success(response.data.message, {
              autoClose: 1000,
              position: toast.POSITION.TOP_RIGHT,
              style: { fontSize: '24px', borderRadius: '10px', color: 'red' },
            });
            setIsLoading(false);
            // setChangedRows(new Set());
            setTimeout(() => { search(); }, 3000);
          }
        })
        .catch(function (error) {
          window.alert(error);
          setIsLoading(false);
        })
    } else {
      toast.error("No Valid Data !", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
        style: { fontSize: '24px', borderRadius: '10px' },
      });

    }

  };
  const Change_schedule = () => {
    let changedData = [...ChangedRows_update].map(index => {
      return postList[index];
    });
    console.log(changedData);
    if (changedData.length > 0) {
      setIsLoading(true);
      Axios.post(`https://` + host + `/schedule/Change`, { changedData: JSON.stringify(changedData), token: token, user: user, date: (getFormattedDateTime(Date1)) })
        .then((response) => {
          if (response.status === 200) {
            toast.success(response.data.message, {
              autoClose: 1000,
              position: toast.POSITION.TOP_RIGHT,
              style: { fontSize: '24px', borderRadius: '10px', color: 'red' },
            });
            setEdit(true);
            setIsLoading(false);
            setTimeout(() => { search(); }, 3000);
          }
        })
        .catch(function (error) {
          setIsLoading(false);
        })
    } else {
      toast.error("No Valid Data !", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
        style: { fontSize: '24px', borderRadius: '10px' },
      });
    }

  }
  const [shiftValue, setShiftValue] = useState('');

  useEffect(() => {
    // This effect will run whenever the `shift` state changes
    const shift_selection = () => {
      switch (shift) {
        case 'Morning_Operators':
          setShiftValue('Morning Shift');
          break;
        case 'Night_Operators':
          setShiftValue('Night Shift');
          break;
        case 'Woodshop':
          setShiftValue('Woodshop/Upholstery');
          break;
        default:
          setShiftValue('');
      }
    };

    shift_selection();
  }, [shift]);

  const groupBySection = (data) => {
    return data.reduce((acc, item) => {
      const section = item.Section;
      if (!acc[section]) {
        acc[section] = [];
      }
      acc[section].push(item);
      return acc;
    }, {});
  };

  // Grouping the postList data by Section
  const groupedData = groupBySection(postList);

  const handlePrint = () => {
    const table = document.querySelector('#alldata');
    const printWindow = window.open("", "", "height=600,width=800");
    const img = document.createElement('img');
    img.src = real_l;

    printWindow.document.write(`
      <html>
        <head>
          <title>Production Schedule</title>
          <style>
          h1 {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            text-align: center; /* Center the heading */
            width: 100%; /* Occupy the full width */
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          img {
            display: inline-block;
            height: 50px; /* Set the height of the image as required */
            margin-right: 10px; /* Set the spacing between the image and title as required */
          }
          
          table {
            border: 1px solid black;
            border-collapse: collapse;
            width: 100%;
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          th, td {
            border: 1px solid black;
            padding: 5px;
          }
          
          tr:nth-child(even) {
            background-color: #f2f2f2; /* Apply the table-striped style */
          }
          
          .bottom-right {
            position:relative;
            bottom: 10px;
            right: 10px;
            font-family: Arial, sans-serif; /* Set the font family */
          }
        
          </style>
        </head>
        <body>
  
          <h1>${img.outerHTML} </h1>
          <h3>${shiftValue} </h3>
          <table class="table table-striped">${(table.innerHTML)}</table>
          <p class="bottom-right">Printed By: ${user}</p>
          <p class="bottom-right">Date Created: ${formattedDate + '  ' + formattedTime}</p>
        </body>
      </html>`
    );
    printWindow.document.querySelector('title').insertAdjacentHTML('afterbegin', `  
    `);
    printWindow.document.close();
    printWindow.focus();
  };
  const groupedDataBySection = {};

  Object.keys(groupedData).forEach(section => {
    groupedDataBySection[section] = {};

    groupedData[section].forEach(item => {
      if (!groupedDataBySection[section][item.Operator]) {
        groupedDataBySection[section][item.Operator] = [];
      }
      groupedDataBySection[section][item.Operator].push(item);
    });
  });


  useEffect(() => {
    let changedData = [...changedRows].map(index => {
      return postList[index];
    });

    if (changedData.length > 0) {
      Update();
    }
  }, [changedRows])


  const print = () => {
    let changedData = [...ChangedRows_update].map(index => {
      return postList[index];
    });
    console.log(changedData);
  }
  const clear = () => {
    setChangedRows(new Set());

  }

  const isValidDate = (dateString) => {
    // Check if date is not '00-00-0000' and is a valid date
    const dateRegex = /^\d{2}-\d{2}-\d{4}$/;
    if (!dateRegex.test(dateString)) return false;

    const [day, month, year] = dateString.split('-').map(Number);
    const date = new Date(`${year}-${month}-${day}`);
    return date instanceof Date && !isNaN(date);
  };

  // Add single entry (modification of schedule)
  const validateData = () => {
    for (const entry of data) {
      if (!entry.Date) {
        alert('Invalid Date');
        return false;
      }
      if (!entry.Operator) {
        alert('Operator is required');
        return false;
      }
      if (!entry.Part_internalNo) {
        alert('Part_internalNo is required');
        return false;
      }
    }
    return true;
  };

  const Submit_data = () => {

    if (validateData()) {

      const formattedData = data.map((item) => [
        item.Date,
        item.Operator,
        item.Part_internalNo,
        item.PartNo,
        item.Customer,
        item.Part_description,
        item.Target,
        item.work_station,
        item.Supervisor_notes,
        item.Operator_2,
        item.Schedule_entry,
        item.Schedule_Date_Time, // Format datetime
        item.Shift,
      ]);
      setTimeout(() => {
        Axios.post(`https://` + host + `/realapi/Schedule_entry`, { filteredData: formattedData, token }).then((response) => {
          if (response.status === 200) {
            window.alert(response.data.message);
            // window.localStorage.removeItem("data");
            setIsLoading(false);
            window.location.reload();

          }
        })
          .catch(error => {
            window.alert("Error Occured")
            setIsLoading(false);
          });
      }, 2000)
    } else {
      console.error('Validation failed');
    }

    setTimeout(() => {
      Axios.post(`https://` + host + `/realapi/Schedule_entry`, { filteredData: data, token }).then((response) => {
        if (response.status === 200) {
          window.alert(response.data.message);
          // window.localStorage.removeItem("data");
          setIsLoading(false);
          window.location.reload();

        }
      })
        .catch(error => {
          window.alert("Error Occured")
          setIsLoading(false);
        });
    }, 2000)
  }
  return (
    <div className="MainPage_data">
      <div class="Container" id="scheduling-whole-block">
        <ToastContainer /><br></br>
        <h2 id="page-title">Production Scheduling</h2><br></br>
        <div class="row" id="filter-row">
          <div class="col-md-3 col-sm-6 mb-3">
            <div class="row mb-2">
              <input type="text" class="form-control form-rounded" placeholder="Operator" list="operators" onChange={(e) => setOperator(e.target.value)} onSelect={search} />
              <datalist id="operators">
                <option></option>
                {operators.map(x => <option key={x.Name}>{x.Name}</option>)}
              </datalist>
            </div>
            <div class="row">
              <select className="form-control form-rounded" onChange={handleDateChange}>
                <option value="yesterday">Yesterday</option>
                <option value="today">Today</option>
                <option value="tomorrow">Tomorrow</option>
              </select>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 mb-3">
            <div class="row mb-2">
              <input type="date" class="form-control form-rounded" defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => setStartdate(e.target.value)} onSelect={search} />
            </div>
            <div class="row">
              <input type="date" class="form-control form-rounded" defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => setEnddate(e.target.value)} onSelect={search} />
            </div>
          </div>

          <div class="col-md-3 col-sm-6 mb-3">
            <div class="row mb-2">
              <input type="text" class="form-control form-rounded" placeholder="Customer" list="Customers" onChange={(e) => setCustomer(e.target.value)} onSelect={search} />
              <datalist id="Customers">
                <option></option>
                {Customers.map(x => <option key={x.Customer}>{x.Customer}</option>)}
              </datalist>
            </div>
            <div class="row">
              <select name="shift" className="form-control form-rounded" placeholder="Select Shift" value={shift} onChange={(e) => setShift(e.target.value)}>
                <option value="">--Select Shift--</option>
                <option value="Morning_Operators">Morning Shift</option>
                <option value="Night_Operators">Night Shift</option>
                <option value="Woodshop">Woodshop/Upholstery</option>
              </select>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 mb-3">
            <div class="row mb-2">
              <input type="text" class="form-control form-rounded" placeholder="Internal Part Number" list="InternalPartNo" onChange={(e) => setPartInternalNo(e.target.value)} onSelect={search} />
              <datalist id="InternalPartNo">
                <option></option>
                {part_dataset.map(x => <option key={x.Sr_No}>{x.Part_internalNo}</option>)}
              </datalist>
            </div>
            <div class="row">
              <div class="col"><input type="text" class="form-control form-rounded" placeholder="Select Department" list="Department" onChange={(e) => setDepartment(e.target.value)} onSelect={search} />
                <datalist id="Department">
                  <option></option>
                  {uniqueSections.map((section, index) => (
                    <option key={index} value={section}>{section}</option>
                  ))}
                </datalist></div>
              <div class="col"><input type="text" class="form-control form-rounded" placeholder="Select Machine" list="Machine" onChange={(e) => setMachine(e.target.value)} onSelect={search} />
                <datalist id="Machine">
                  <option></option>
                  {Machine_list.map(x => <option key={x.Machines}>{x.Machines}</option>)}
                </datalist></div>

            </div>
          </div>

          <div class="col-md-2 col-sm-12 text-center mt-2">
            <Link to="/SchedulingEntry">
              <button class="btn btn-primary btn-block">Create Schedule</button>
            </Link>
          </div>
          <div class="col-md-2 col-sm-12 text-center mt-2">
            <Link to="/RetrieveData">
              <button class="btn btn-primary btn-block">Production History</button>
            </Link>
          </div>
        </div>
        <div>
        </div>
        <div>
          <button className="btn btn-primary" onClick={handlePrint}>Print Table</button>
          <button className="btn btn-primary" onClick={() => { setAllColumns(!AllColumns) }}>All Columns </button>
          <button type='button' class="btn btn-primary" onClick={() => { ToggleEdit() }}> Edit</button>
          <Link to="/Morning">
            <button type='button' class="btn btn-success">
              <span class="glyphicon glyphicon-star" aria-hidden="true"></span> Back</button>
          </Link>
        </div>
        {Edit ?

          <table class="table table-bordered" >
            <thead>
              <tr>
                <th>Date</th>
                <th>Operator</th>
                <th>Part Internal No</th>
                {AllColumns ? <th>PART No</th> : null}
                {AllColumns ? <th>CUSTOMER</th> : null}
                {AllColumns ? <th>Part Description</th> : null}
                <th>Target</th>
                <th>Work Station</th>
                <th>Produced</th>
                <th>Scrap</th>
                <th>Operator-2 </th>
                <th>Supervisor Notes</th>
                <th>Submit</th>
              </tr>
            </thead>
            {postList.map((val, index) => {
              return (
                <tbody id="tbody">
                  <tr key={index}>
                    <td>{val.Date.slice(0, 10)}</td>
                    <td>{val.Operator}</td>
                    <td>{val.Part_internalNo}</td>
                    {AllColumns ? <td>{val.PartNo}</td> : null}
                    {AllColumns ? <td>{val.Customer}</td> : null}
                    {AllColumns ? <td>{val.Part_description}</td> : null}
                    <td>{val.Target}</td>
                    <td>{val.work_station}</td>
                    <td><input
                      type="number"
                      className="form-control form-rounded"
                      Value={val.Produced}
                      onChange={(e) => handleUpdate(e, index, 'Produced')} /></td>
                    <td><input
                      type="number"
                      className="form-control form-rounded"
                      Value={val.Scrap}
                      onChange={(e) => handleUpdate(e, index, 'Scrap')}
                    /></td>
                    <td><input
                      type="text"
                      className="form-control form-rounded"
                      value={val.Operator_2}
                      list="operators"
                      onChange={(e) => handleUpdate(e, index, 'Operator_2')}
                    /></td><td><input
                      type="text"
                      className="form-control form-rounded"
                      Value={val.Supervisor_notes}
                      onBlur={(e) => handleUpdate(e, index, 'Supervisor_notes')}
                    /></td>

                    <td><button class="btn btn-primary" onClick={() => { window.confirm('Are you sure you want to Update this Entry?',) && Update() }}> Submit</button></td>
                  </tr>
                </tbody>
              )
            })}
          </table> :

          <div>
            <br></br>
            <div class="add_section" id='Part_dataset_add'>
              {data.map((line, index) => {
                return (
                  <div key={index} className='row'>
                    <div className="col">
                      <input
                        type="date"
                        className="form-control form-rounded"
                        onChange={(e) => {
                          const newData = [...data];
                          newData[index].Date = e.target.value;
                          setData(newData);
                        }}
                        Value={data[index].Date}
                      />

                    </div>
                    <div className="col">
                      <input
                        type="text"
                        className="form-control form-rounded"
                        onChange={(e) => {
                          const newData = [...data];
                          newData[index].Operator = e.target.value;
                          setData(newData);
                        }}
                        list="operators"
                        id="operators"
                        placeholder="Enter Operator"
                      />
                      <datalist id="operators">
                        <option></option>
                        {operators.map(x => <option key={x.Name}>{x.Name}</option>)}
                      </datalist>
                    </div>
                    <div className="col-2">
                      <input
                        type="text"
                        className="form-control form-rounded"
                        onChange={(e) => {
                          const newData = [...data];
                          newData[index].Part_internalNo = e.target.value;
                          setData(newData);
                        }}
                        onBlur={(e) => submitValue_Singlepart(e, index)} // Call the submitValue function on blur
                        list="InternalPartNo"
                        id="operator"
                        placeholder="Enter Internal Part No"
                      />
                      <div className="scrollbar">
                        <datalist id="InternalPartNo">
                          <option key="empty-option"></option>
                          {part_dataset.map((x, idx) => (
                            <option key={x.Part_internalNo + idx}>{x.Part_internalNo}</option>
                          ))}
                        </datalist>
                      </div>
                    </div>
                    <div className="col-1">
                      <input
                        type="text"
                        className="form-control form-rounded"
                        onChange={(e) => {
                          const newData = [...data];
                          newData[index].Target = e.target.value;
                          setData(newData);
                        }}
                        placeholder="Target"
                        name="target"
                        required
                      />
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        className="form-control form-rounded"
                        onChange={(e) => {
                          const newData = [...data];
                          newData[index].work_station = e.target.value;
                          setData(newData);
                        }}
                        placeholder="Work Station"
                        list="Work_station"
                      />
                      <datalist id="Work_station">
                        <option key="empty-option"></option>
                        {Machine_list.map(x => <option key={x.Machines}>{x.Machines}</option>)}
                      </datalist>
                    </div>
                    <div className="col-1">
                      <input
                        type="text"
                        className="form-control form-rounded"
                        onChange={(e) => {
                          const newData = [...data];
                          newData[index].Operator_2 = e.target.value;
                          setData(newData);
                        }}
                        placeholder="Operator-2 (Optional)"
                        name="operator_2"
                        list="operators"
                      />
                    </div>
                    <div className="col-2">
                      <label>
                      </label>
                      <textarea
                        className="form-control form-rounded"
                        onChange={(e) => {
                          const newData = [...data];
                          newData[index].Supervisor_notes = e.target.value;
                          setData(newData);
                        }}

                        placeholder="Supervisor Notes"
                        name="Supervisor_notes"
                      />
                    </div>
                    <div className="col-sm-1">
                      <button key={line.Operator} onClick={() => addNewRow(index, line.Operator, line.work_station)} className="form-control form-rounded">
                        Add {(line.Operator).split(0, 10)}
                      </button>
                    </div>
                  </div>
                )
              })}

              <button class="btn btn-primary" onClick={Submit_data}>Submit</button>
              <br></br>
            </div>
            <br></br>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th><input
                    type="checkbox"
                    class="form-check-input"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  /></th>
                  <th>Date</th>
                  <th>Operator</th>
                  <th>Part Internal No</th>
                  <th>PART No</th>
                  <th>CUSTOMER</th>
                  <th>Part Description</th>
                  <th>Target</th>
                  <th>Work Station</th>
                  <th>Operator-2 </th>
                  <th>Supervisor Notes</th>
                  <th>Update</th>
                  <th>Delete</th>
                </tr>
              </thead>
              {postList.map((val, index) => {
                return (
                  <tbody id="tbody">
                    <tr key={index}>
                      <td><input
                        type="checkbox"
                        class="form-check-input"
                        checked={selectedRows.some(row => row.SR_No === val.SR_No)}
                        onChange={(e) => handleCheckboxChange(e, val)}
                      /></td>
                      <td><input
                        type="date"
                        className="form-control form-rounded"
                        Value={val.Date.slice(0, 10)}
                        list="operators"
                        onChange={(e) => handleUpdate(e, index, 'Date')}
                      /></td>
                      <td><input
                        type="text"
                        className="form-control form-rounded"
                        Value={val.Operator}
                        list="operators"
                        onChange={(e) => handleUpdate(e, index, 'Operator')}
                      /></td>
                      <td><input
                        type="text"
                        className="form-control form-rounded"
                        Value={val.Part_internalNo}
                        list="InternalPartNo"
                        onChange={(e) => handleUpdate(e, index, 'Part_internalNo')}
                      /> <datalist id="InternalPartNo">
                          <option></option>
                          {part_dataset.map((x) => {
                            return <option>{x.Part_internalNo}</option>;
                          })}
                        </datalist></td>
                      <td>{val.PartNo}</td>
                      <td>{val.Customer}</td>
                      <td>{val.Part_description}</td>
                      <td><input
                        type="number"
                        className="form-control form-rounded"
                        Value={val.Target}
                        onChange={(e) => handleUpdate(e, index, 'Target')}
                      /></td>
                      <td><input
                        type="text"
                        className="form-control form-rounded"
                        Value={val.work_station}
                        list="Work_station"
                        onChange={(e) => handleUpdate(e, index, 'work_station')}
                      />
                        <datalist id="Work_station">
                          <option></option>
                          {Machine_list.map(x => {
                            return <option>{x.Machines}</option>
                          })}

                        </datalist></td>
                      <td><input
                        type="text"
                        className="form-control form-rounded"
                        Value={val.Operator_2}
                        list="operators"
                        onChange={(e) => handleUpdate(e, index, 'Operator_2')}
                      /></td><td><input
                        type="text"
                        className="form-control form-rounded"
                        Value={val.Supervisor_notes}
                        onBlur={(e) => handleUpdate(e, index, 'Supervisor_notes')}
                      /></td>
                      <td><button class="btn btn-primary" onClick={() => { window.confirm('Are you sure you want to Update the schedule ?',) && Change_schedule(val.SR_No) }}> Update</button></td>
                      <td><button class="btn btn-danger" onClick={() => { window.confirm('Are you sure you want to delete this Entry?' + val.SR_No,) && Delete(val.SR_No) }}> Delete</button></td>
                    </tr>
                  </tbody>
                )
              })}
            </table>
          </div>}
      </div>
      {isLoading ?
        <div className='overlay'>
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div> : null}




      {/* Schedule print page */}
      <div id="alldata" style={{ "display": "none" }}>

        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Operator</th>
              <th>Part Internal No</th>
              <th>Target</th>
              <th>Work Station</th>
              <th>Operator-2</th>
              <th>Supervisor Notes</th>
            </tr>
          </thead>
          {Object.keys(groupedDataBySection).map((section, sectionIndex) => (
            <tbody key={sectionIndex}>
              <tr>
                <td colSpan="6" style={{ backgroundColor: '#f8f9fa' }}>
                  <b>{section}</b>
                </td>
              </tr>
              {Object.keys(groupedDataBySection[section]).map((operator, operatorIndex) => {
                const operatorData = groupedDataBySection[section][operator];
                return operatorData.map((val, idx) => (
                  <tr key={idx}>
                    {idx === 0 && (
                      <td rowSpan={operatorData.length} style={{ backgroundColor: '#e9ecef', verticalAlign: 'top' }}>
                        {operator}
                      </td>
                    )}
                    <td>{val.Part_internalNo}</td>
                    <td>{val.Target}</td>
                    <td>{val.work_station}</td>
                    <td>{val.Operator_2}</td>
                    <td>{val.Supervisor_notes}</td>
                  </tr>
                ));
              })}
            </tbody>
          ))}
        </table>
      </div>
    </div >
  )
}

export default Scheduling;